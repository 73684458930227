const svStrings = {
  save: 'Spara ändringarna',
  savedSuccess: 'Ändringarna har sparats!',
  savedError: 'Det gick inte att spara just nu, försök senare igen',
  '404Error': 'Sidan kunde inte hittas',
  dateError: 'Datumet måste vara mellan 1900-01-01 och ',
  unknownError: 'Okänt fel',
  apiError: 'Server fel',
  backToStart: 'Tillbaka till startsidan',
  loading: 'Laddar...',
  logOut: 'Logga ut',
  menu: 'Mer',
  close: 'Stäng',
  faq: 'FAQ',

  // Login page
  loggingIn: 'Du loggas in',
  redirecting: 'Du skickas vidare till Fotbollskonto',
  justASec: 'Det kan ta några sekunder',

  // Info page
  info: 'Kontouppgifter',
  myAccount: 'Mitt konto',
  userEmailAndAccount: 'E-postadress och användarnamn',
  givenName: 'Förnamn',
  surname: 'Efternamn',
  cellphone: 'Mobilnummer',
  birthday: 'Födelsedatum',
  accountHelpText: 'Har du frågor om ditt konto, kontakta',
  gdprNotice:
    'Om du önskar bli permanent borttagen från våra system eller vill begära att få en sammanställning av din kunddata, maila',
  gdprEmail: 'dpo@svenskelitfotboll.se',
  allsvenskan: 'Allsvenskan',
  superettan: 'Superettan',
  linkedAccounts: 'E-postadress och partnernätverk',
  linkedAccountsDisclaimer:
    'Fältet e-postadress partnernätverk visar kopplingar till partnernätverk. Vid frågor eller ändringar, kontakta partnerklubben.',

  // Consents page
  consents: 'Kommunikation',
  partner: 'Partner',
  favTeam: 'Favoritlag',
  favTeamDisclamer1: 'Genom att välja favoritlag bekräftar du att du tagit del av ditt',
  favTeamDisclamer2:
    'och att du önskar få kommunikation och marknadsföring från den valda klubben.',
  favTeamPrivacyPolicy: 'favoritlags integritetspolicy',
  teamsPrivacyPolicyUrl: 'https://www.svenskelitfotboll.se/integritetspolicy-klubbar/',
  selectTeam: 'Välj ett lag...',
  email: 'Mail',
  sms: 'SMS',
  sender: 'Prenumerationer',
  senderTooltip: 'Här visas prenumerationer från lag eller ligor du har köpt biljett till.',

  // Tickets page
  tickets: 'Biljetter',
  ticketsEmpty:
    'Här samlas dina aktiva biljetter från klubbarna i Allsvenskan och Superettan. Du har för närvarande inga biljetter att visa.',
  seasonsTicket: 'Säsongsbiljett',

  // Subscription page
  subscription: 'Abonnemang',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  cancelled: 'Avbruten',
  validUntil: 'Giltigt t.o.m:',
  started: 'Startades:',
  nextPayment: 'Nästa betalning:',
  cancelledAt: 'Avslutades:',
  status: 'Status:',
  orderNumber: 'Ordernummer:',
  cancel: 'Avsluta abonnemang',
  changePayment: 'Ändra betalsätt',
  buy: 'Starta abonnemang',
  currencyPerMonth: 'kr/mån',
  cancelProduct: 'Avsluta {{product}}',
  continueProduct: 'Fortsätt med {{product}}',
  confirmCancelText1: 'Vad tråkigt att du vill avsluta ditt abonnemang. Är du helt säker?',
  confirmCancelAccountText1:
    'Vad tråkigt att du vill avsluta ditt Fotbollskonto. Är du helt säker?',
  confirmCancelAccountText2:
    'Med ett Fotbollskonto får du tillgång till flertalet tjänster, såsom biljettköp, inloggning i app, Fotboll Play och Allsvenskan Fantasy.',
  confirmCancelText2:
    'Om du väljer att avsluta ditt abonnemang, kommer du att kunna fortsätta använda tjänsten till och med',
  confirmCancelText3:
    'Du kan återaktivera {{product}} under {{subscription}} i ditt Fotbollskonto.',
  confirmCancelText4:
    'Har du frågor eller tankar kring hur vi kan förbättra tjänsten? Hör gärna av dig till',
  subscriptionCancelledSuccess: 'Ditt abonnemang är nu avslutat.',
  subscriptionCancelledError: 'Det gick inte att avsluta ditt abonnemang just nu, försök senare.',
  readMore: 'Läs mer om tjänsten',

  // Payment page
  payment: 'Betalningsuppgifter',
  changePaymentMethod: 'Ändra betaluppgifter',
  paymentHistroy: 'Betalningshistorik',
  paymentHistoryEmpty: 'Du har för närvarande inga transaktioner att visa.',
  completed: 'Betald',
  initiated: 'Påbörjad',

  // Account page
  account: 'Kontosammanfattning',
  welcome: 'Välkommen',
  youAreLoggedInAs: 'Du är inloggad som',
  youHaveAnActiveSubscription:
    'Du har en aktiv {{product}} prenumeration som är giltig till och med {{validUntil}}.',
  activeSubscriptionWarning:
    'Du har en aktiv prenumeration som kommer att avbrytas on du avslutar ditt konto.',
  activeSubscriptions: 'Aktiva abonnemang:',
  activeSubscription: '{{product}} giltig t.o.m ',
  removeAccount: 'Fotbollskonto',
  removeAccount1: 'Avsluta Fotbollskonto',
  confirm: 'Bekräfta',
  continueToDeleteAccount: 'Fortsätt till Avsluta konto',
  timeLeftToUseHighlightsPlus1:
    'Om du avslutar ditt Fotbollskonto kommer du inte längre ha tillgång till:',
  continueHavingAccount: 'Ha kvar mitt Fotbollskonto',
  needToCancelHighlightsPlus1:
    'Du har ett aktivt Highlights Plus abonnemang som är giltigt till och med ',
  needToCancelHighlightsPlus2:
    'För att avsluta ditt Fotbollskonto måste ditt Highligts Plus abonnemang först avslutas. Detta gör du under Abonnemang i menyn.',
  needToCancelSubscription:
    'Innan du avslutar Fotbollskontot måste dina aktiva abonnemang avslutas. Detta gör du under Abonnemang i menyn.',
  confirmRemoveAccount: 'Är du säker på att du vill avsluta ditt Fotbollskonto?',
  verifyByEmail: 'Ange din e-postadress för att bekräfta',
  accountDeleted: 'Ditt Fotbollskonto är nu avslutat',
  confirmEmailHasBeenSent: 'Ett bekräftelsemail har skickats till din e-postadress.',
  toSubscription: 'Till Abonnemang',
  alreadyDeleted: 'Fotbollskontot har redan avslutats',
  accountDeletionFailed: 'Kunde inte avsluta Fotbollskontot',
  somethingFailed: 'Något gick fel',
  logoutIn: 'Du loggas nu ut om {s} sekunder',

  // My Account page
  titleSubscription: 'Vad ingår i mitt fotbollskonto?',
  subscriptionInfo: `Med Fotbollskonto loggar du in på samtliga Svensk Elitfotbolls tjänster och även i vissa klubbmiljöer (klubbens webb) direkt med samma login-uppgifter överallt.
Det gör det enklare för dig - och du behöver bara komma ihåg ett lösenord.`,
  subscriptionLinks:
    'Tjänster och funktionalitet du får tillgång till, med ditt gratis Fotbollskonto:',
  titleCancelSubscription: 'Vill du avsluta ditt fotbollskonto?',
  cancelSubscriptionInfo: `Om du väljer att avsluta ditt Fotbollskonto förlorar du möjligheten att använda dig av Svensk Elitfotbolls tjänster. Det betyder att du inte kan se eventuella aktiva
matcher du köpt pay-per-view (PPV), eller gratis innehåll på Fotboll Play. Du kan inte spela Allsvenskan Fantasy och du förlorar din Fantasy-historik. Du kan
inte köpa eller logga in och se dina biljetter hos AXS och du kan inte se dina biljetter i app. Är du medlem i affärsnätverk som klubbpartner kan du
inte längre nå partnerappen när du avslutat ditt Fotbollskonto.`,
  subscriptionsEmpty: 'Du har för närvarande inga abonnemang att visa.',

  // Footer
  copy: '© Svensk Elitfotboll 2025',
  supportEmail: 'support@svenskelitfotboll.se',
  website: 'https://svenskelitfotboll.se',
};

const strings = {
  supportedLanguages: ['sv'],
  sv: svStrings,
};
export default strings;
