import * as React from 'react';
import styled from 'styled-components';
import { CEE } from 'api/cee/types';
import { device } from 'style/device';

const Text = styled.h2`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 17px;
  font-weight: normal;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: 400;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #c9c9c9;

  &:last-child {
    border-bottom: 0;
  }
`;

const Grid = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
`;

const GridItem = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 80px;
  max-width: 140px;

  @media ${device.maxMobile} {
    min-width: unset;
  }
`;

const QrDiv = styled.div`
  height: 100%;
  width: 100%;
`;

type TicketDetailsProps = {
  ticket: CEE.Ticket;
  qrImage: string;
  index: number;
  amount: number;
};

const TicketDetails = ({ ticket, qrImage, index, amount }: TicketDetailsProps) => {
  return (
    <Container>
      <Text>{`Biljett ${index + 1} av ${amount}`}</Text>
      {qrImage && <QrDiv dangerouslySetInnerHTML={{ __html: qrImage }} />}
      <Grid>
        <GridItem>
          <Subtitle>Ingång</Subtitle>
          <Text>{ticket.Location.Entrance}</Text>
        </GridItem>
        <GridItem>
          <Subtitle>Sektion</Subtitle>
          <Text>{ticket.Location.Section}</Text>
        </GridItem>
        {ticket.Location.Row && (
          <GridItem>
            <Subtitle>Rad</Subtitle>
            <Text>{ticket.Location.Row || '-'}</Text>
          </GridItem>
        )}
        {ticket.Location.Seat && (
          <GridItem>
            <Subtitle>Stol</Subtitle>
            <Text>{ticket.Location.Seat || '-'}</Text>
          </GridItem>
        )}
      </Grid>
    </Container>
  );
};

export default TicketDetails;
